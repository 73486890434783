import React, { ReactElement, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Texture } from "three";
import {
  BackgroundRenderer,
  Background,
  loadImage,
  TransitionType,
  EffectType,
  Easings,
} from "../dist/midori";

function getTransitionConfig(type: TransitionType) {
  switch (type) {
    default:
      return {};
  }
}

function setBackgroundEffects(background: Background, effects: EffectType[]) {
  const { effects: backgroundEffects } = background;
  backgroundEffects.removeAll();
  for (const effect of effects) {
    switch (effect) {
      case EffectType.Blur:
        backgroundEffects.set(EffectType.Blur, { radius: 1.5, passes: 5 });
        break;
      case EffectType.MotionBlur:
        backgroundEffects.set(EffectType.MotionBlur, {
          intensity: 8,
          samples: 32,
        });
        break;

      case EffectType.Bloom:
        backgroundEffects.set(EffectType.Bloom, {
          opacity: 1,
          radius: 30,
          passes: 60,
        });
        break;
      case EffectType.RgbShift:
        backgroundEffects.set(EffectType.RgbShift, {
          amount: 0.006,
          angle: 135,
        });
        break;
      case EffectType.Vignette:
        backgroundEffects.set(EffectType.Vignette, {
          darkness: 0.5,
          offset: 1,
        });
        break;
      case EffectType.VignetteBlur:
        backgroundEffects.set(EffectType.VignetteBlur, {
          size: 1,
          radius: 1,
          passes: 2,
        });
        break;
    }
  }
}

function setBackgroundParticles(background: Background) {
  const { particles } = background;
  particles.generate([
    {
      name: "small",
      amount: 100,
      maxSize: 9,
      maxOpacity: 0.8,
      minGradient: 0.75,
      maxGradient: 1.0,
    },
    {
      name: "medium",
      amount: 50,
      maxSize: 12,
      maxOpacity: 0.8,
      minGradient: 0.75,
      maxGradient: 1.0,
      smoothing: 0.8,
    },
    {
      name: "large",
      amount: 22,
      minSize: 100,
      maxSize: 125,
      maxOpacity: 0.14,
      minGradient: 1.0,
      maxGradient: 1.0,
      smoothing: 0.65,
    },
  ]);
  particles.move(
    "small",
    { distance: 0.5, angle: 25 },
    { duration: 1, loop: true }
  );
  particles.move(
    "medium",
    { distance: 0.3, angle: 45 },
    { duration: 5, loop: true }
  );
  particles.move(
    "large",
    { distance: 0.4, angle: 35 },
    { duration: 5, loop: true }
  );
  particles.sway(
    "small",
    { x: 0.025, y: 0.025 },
    { duration: 1.5, easing: Easings.Sinusoidal.InOut, loop: true }
  );
  particles.sway(
    "medium",
    { x: 0.025, y: 0.025 },
    { duration: 1.5, easing: Easings.Sinusoidal.InOut, loop: true }
  );
  particles.sway(
    "large",
    { x: 0.025, y: 0.025 },
    { duration: 1.5, easing: Easings.Sinusoidal.InOut, loop: true }
  );
}

function setRendererBackground(
  renderer: BackgroundRenderer,
  background: Texture,
  transition: TransitionType
) {
  const delay = 0;
  renderer.setBackground(background, {
    type: transition,
    config: {
      ...getTransitionConfig(transition),
      delay,
      onInit: (prevBackground, nextBackground) => {
        prevBackground.camera.move(
          {
            x: Math.random() * 5,
            y: Math.random() * 5,
            z: 0.3 + Math.random() * 0.7,
          },
          {
            duration: 3,
            easing: Easings.Quartic.In,
          }
        );

        prevBackground.camera.rotate(-5 + Math.random() * 10, {
          duration: 2.5,
          easing: Easings.Quartic.In,
        });
        nextBackground.camera.move(
          {
            x: Math.random() * 5,
            y: Math.random() * 5,
            z: 0.7 + Math.random() * 0.3,
          },
          {
            duration: 2,
            delay,
            easing: Easings.Quartic.Out,
          }
        );
        let dur = 3;
        if (
          navigator.userAgent.match(/Android/i) ||
          navigator.userAgent.match(/webOS/i) ||
          navigator.userAgent.match(/iPhone/i) ||
          navigator.userAgent.match(/iPad/i) ||
          navigator.userAgent.match(/iPod/i) ||
          navigator.userAgent.match(/BlackBerry/i) ||
          navigator.userAgent.match(/Windows Phone/i)
        ) {
          dur = 12;
        }
        nextBackground.camera.sway(
          { x: 1.1, y: 0.05, z: 0.02, zr: 4 },
          {
            duration: dur,
            easing: Easings.Quadratic.InOut,
            loop: true,
          }
        );
      },
    },
  });

  if (!isSp) {
    setBackgroundParticles(renderer.background);
  }
}

interface SectionProps {
  className?: string;
  title: string;
  description: string;
  buttonText: string;
  buttonLink: string;
}

function Section(props: SectionProps): ReactElement<SectionProps> {
  const { className, title, description, buttonText, buttonLink } = props;
  return (
    <>
      <div className={className}>
        <div className="main-title">{title}</div>
        <div className="main-description">{description}</div>
        <div className="main-button">{buttonText}</div>
      </div>
    </>
  );
}

interface Images {
  image: Texture;
}

interface ExampleProps {
  images: Images[];
  isSp: boolean;
}

function Example(props: ExampleProps): ReactElement<ExampleProps> {
  const { images, isSp } = props;

  const [canvasRef, setCanvasRef] = useState<HTMLCanvasElement | null>(null);
  const [renderer, setRenderer] = useState<BackgroundRenderer>();
  useEffect(() => {
    if (canvasRef !== null) {
      const backgroundRenderer = new BackgroundRenderer(canvasRef);
      setRenderer(backgroundRenderer);
      return () => backgroundRenderer.dispose();
    }
  }, [images, canvasRef]);

  const transitionRef = useRef<TransitionType>(TransitionType.Wipe);
  const [transition, setTransition] = useState<TransitionType>(
    transitionRef.current
  );
  useEffect(() => {
    transitionRef.current = transition;
  }, [transition]);

  const [index, setIndex] = useState(0);
  useEffect(() => {
    if (renderer !== undefined) {
      setRendererBackground(
        renderer,
        images[index].image,
        transitionRef.current
      );
    }
  }, [images, index, renderer]);

  const [effects, setEffects] = useState<EffectType[]>(
    !isSp
      ? [
          EffectType.Bloom,
          EffectType.MotionBlur,
          EffectType.Vignette,
          EffectType.VignetteBlur,
          EffectType.RgbShift,
        ]
      : []
  );
  useEffect(() => {
    if (renderer !== undefined) {
      setBackgroundEffects(renderer.background, effects);
    }
  }, [effects, index, renderer]);
  const title = `ПОГРУЗИТЬСЯ В ГЛУБОКОЕ ОЗЕРО`.toLocaleUpperCase();
  const description = `Вы можете Вы можете погрузиться в любое количество озер возможностей.`;
  const buttonLink = "#";
  const buttonText = "Путешествие по Байкалу";
  return (
    <>
      <canvas ref={setCanvasRef} className="canvas" />
      <Section className={"select-item"} title={"Это Байкал"}></Section>
      <Section
        className={"main-section"}
        title={title}
        description={description}
        buttonLink={buttonLink}
        buttonText={buttonText}
      ></Section>
    </>
  );
}

let path = "";
let isSp = false;
if (
  navigator.userAgent.match(/Android/i) ||
  navigator.userAgent.match(/webOS/i) ||
  navigator.userAgent.match(/iPhone/i) ||
  navigator.userAgent.match(/iPad/i) ||
  navigator.userAgent.match(/iPod/i) ||
  navigator.userAgent.match(/BlackBerry/i) ||
  navigator.userAgent.match(/Windows Phone/i)
) {
  path = "assets/8-sp.png";
  isSp = true;
} else {
  path = "assets/7.png";
}

Promise.all([
  loadImage(path).then((image) => ({
    image,
  })),
])
  .then((images) => {
    ReactDOM.render(
      <Example images={images} isSp={isSp} />,
      document.getElementById("root")
    );
  })
  .catch((e) => console.error(`Failed to load assets: ${e}`));
